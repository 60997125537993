import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { bankAccountList } from "../../redux/actions/bankAccountActions";
import { bankList } from "../../redux/actions/bankActions";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const chequeByDate = useSelector((state) => state.chequeByDate);
  const { chequeByDates } = chequeByDate;

  const bank = useSelector((state) => state.bank);

  const { banks } = bank;

  console.log("chequesByDates", chequeByDates);

  let arr = !chequeByDates
    ? []
    : Object.keys(chequeByDates).map((k) => chequeByDates[k]);
  arr
    .sort((a, b) => b.id - a.id)
    .forEach((item, index) => (item.id = index + 1));

  console.log(JSON.stringify(arr));

  useEffect(() => {
    dispatch(bankList());
  }, [dispatch]);

  const classes = useStyles();

  return (
    <div>
      {!banks ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
            }}
          >
            <Icon style={{ fontSize: 22, marginTop: 20 }}>
              app_registration
            </Icon>
            <h2 style={{ marginLeft: 5 }}>Cheque Register</h2>
          </div>
          <Table chequesByDates={arr} banks={banks.data} userData={userData} />
        </div>
      )}
    </div>
  );
};

export default Body;
