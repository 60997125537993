import {
  CHEQUE_LIST_REQUEST,
  CHEQUE_LIST_SUCCESS,
  CHEQUE_LIST_FAIL,
  CHEQUE_CREATE_REQUEST,
  CHEQUE_CREATE_SUCCESS,
  CHEQUE_CREATE_FAIL,
  CHEQUE_UPDATE_REQUEST,
  CHEQUE_UPDATE_SUCCESS,
  CHEQUE_UPDATE_FAIL,
  CHEQUE_DELETE_REQUEST,
  CHEQUE_DELETE_SUCCESS,
  CHEQUE_DELETE_FAIL,
  CHEQUE_BY_DATE_REQUEST,
  CHEQUE_BY_DATE_SUCCESS,
  CHEQUE_BY_DATE_FAIL,
} from "../constants/chequeEntryConstants";

export const chequeListReducer = (state = { cheques: [] }, action) => {
  switch (action.type) {
    case CHEQUE_LIST_REQUEST:
      return { loading: true, cheques: [] };
    case CHEQUE_LIST_SUCCESS:
      return { loading: false, cheques: action.payload };
    case CHEQUE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const chequeCreateReducer = (state = { chequeCreates: [] }, action) => {
  switch (action.type) {
    case CHEQUE_CREATE_REQUEST:
      return { loading: true, success: false, chequeCreates: [] };
    case CHEQUE_CREATE_SUCCESS:
      return { loading: false, chequeCreates: action.payload, success: true };
    case CHEQUE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const chequeUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CHEQUE_UPDATE_REQUEST:
      return { loading: true, success: false };
    case CHEQUE_UPDATE_SUCCESS:
      return { loading: false, chequeUpdates: action.payload, success: true };
    case CHEQUE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const chequeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CHEQUE_DELETE_REQUEST:
      return { loading: true, success: false };
    case CHEQUE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CHEQUE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const chequeByDateReducer = (state = { chequeByDates: [] }, action) => {
  switch (action.type) {
    case CHEQUE_BY_DATE_REQUEST:
      return { loading: true, chequeByDates: [] };
    case CHEQUE_BY_DATE_SUCCESS:
      return { loading: false, chequeByDates: action.payload };
    case CHEQUE_BY_DATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
