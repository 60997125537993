export const CHEQUE_LIST_REQUEST = "CHEQUE_LIST_REQUEST";
export const CHEQUE_LIST_SUCCESS = "CHEQUE_LIST_SUCCESS";
export const CHEQUE_LIST_FAIL = "CHEQUE_LIST_FAIL";

export const CHEQUE_CREATE_REQUEST = "CHEQUE_CREATE_REQUEST";
export const CHEQUE_CREATE_SUCCESS = "CHEQUE_CREATE_SUCCESS";
export const CHEQUE_CREATE_FAIL = "CHEQUE_CREATE_FAIL";

export const CHEQUE_UPDATE_REQUEST = "CHEQUE_UPDATE_REQUEST";
export const CHEQUE_UPDATE_SUCCESS = "CHEQUE_UPDATE_SUCCESS";
export const CHEQUE_UPDATE_FAIL = "CHEQUE_UPDATE_FAIL";

export const CHEQUE_DELETE_REQUEST = "CHEQUE_DELETE_REQUEST";
export const CHEQUE_DELETE_SUCCESS = "CHEQUE_DELETE_SUCCESS";
export const CHEQUE_DELETE_FAIL = "CHEQUE_DELETE_FAIL";

export const CHEQUE_PERMISSION_REQUEST = "CHEQUE_PERMISSION_REQUEST";
export const CHEQUE_PERMISSION_SUCCESS = "CHEQUE_PERMISSION_SUCCESS";
export const CHEQUE_PERMISSION_FAIL = "CHEQUE_PERMISSION_FAIL";

export const CHEQUE_BY_DATE_REQUEST = "CHEQUE_BY_DATE_REQUEST";
export const CHEQUE_BY_DATE_SUCCESS = "CHEQUE_BY_DATE_SUCCESS";
export const CHEQUE_BY_DATE_FAIL = "CHEQUE_BY_DATE_FAIL";
