import axios from "axios";

import {
  CHEQUE_LIST_REQUEST,
  CHEQUE_LIST_SUCCESS,
  CHEQUE_LIST_FAIL,
  CHEQUE_CREATE_REQUEST,
  CHEQUE_CREATE_SUCCESS,
  CHEQUE_CREATE_FAIL,
  CHEQUE_UPDATE_REQUEST,
  CHEQUE_UPDATE_SUCCESS,
  CHEQUE_UPDATE_FAIL,
  CHEQUE_DELETE_REQUEST,
  CHEQUE_DELETE_SUCCESS,
  CHEQUE_DELETE_FAIL,
  CHEQUE_BY_DATE_REQUEST,
  CHEQUE_BY_DATE_SUCCESS,
  CHEQUE_BY_DATE_FAIL,
} from "../constants/chequeEntryConstants";

import API_URL from "../../utils/api";

export const chequeList = () => async (dispatch) => {
  try {
    dispatch({
      type: CHEQUE_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/cheque_entries`, config);

    dispatch({
      type: CHEQUE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHEQUE_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const chequeCreateAction =
  (
    payment_mode,
    business_unit_id,
    supplier_id,
    supplier_n,
    project_id,
    bank_account_id,
    receiver_name,
    cheque_no,
    amount,
    issue_date,
    cheque_date,
    narration,
    status,
    cheque_img,
    payment_type,
    print_status
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CHEQUE_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const formData = new FormData();

      formData.append("supplier_id", supplier_id);
      formData.append("supplier_n", supplier_n);
      formData.append("business_unit_id", business_unit_id);
      formData.append("bank_account_id", bank_account_id);
      formData.append("receiver_name", receiver_name);
      formData.append("cheque_no", cheque_no);
      formData.append("amount", amount);
      formData.append("project_id", project_id);
      formData.append("issue_date", issue_date);
      formData.append("status", status);
      formData.append("cheque_date", cheque_date);
      formData.append("narration", narration);
      formData.append("cheque_img", cheque_img);
      formData.append("payment_type", payment_type);
      formData.append("print_status", print_status);
      formData.append("payment_mode", payment_mode);

      const { data } = await axios.post(
        `${API_URL}/api/cheque_entries`,
        formData,
        config
      );

      dispatch({
        type: CHEQUE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CHEQUE_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const chequeUpdateAction =
  (
    id,
    payment_mode,
    business_unit_id,
    supplier_id,
    supplier_n,
    project_id,
    bank_account_id,
    receiver_name,
    cheque_no,
    amount,
    issue_date,
    cheque_date,
    narration,
    status,
    cheque_img,
    payment_type,
    print_status
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CHEQUE_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const formData = new FormData();
      formData.append("project_id", project_id);
      formData.append("supplier_id", supplier_id);
      formData.append("supplier_n", supplier_n);
      formData.append("business_unit_id", business_unit_id);
      formData.append("bank_account_id", bank_account_id);
      formData.append("receiver_name", receiver_name);
      formData.append("cheque_no", cheque_no);
      formData.append("amount", amount);
      formData.append("issue_date", issue_date);
      formData.append("status", status);
      formData.append("cheque_date", cheque_date);
      formData.append("narration", narration);
      formData.append("cheque_img", cheque_img);
      formData.append("payment_type", payment_type);
      formData.append("print_status", print_status);
      formData.append("payment_mode", payment_mode);

      const { data } = await axios.put(
        `${API_URL}/api/cheque_entries/${id}`,
        formData,
        config
      );

      dispatch({
        type: CHEQUE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CHEQUE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const chequeDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CHEQUE_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    await axios.delete(`${API_URL}/api/cheque_entries/${id}`, config);

    dispatch({
      type: CHEQUE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CHEQUE_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const chequeByDateAction =
  (from_date, to_date, bank_id, puser_id) => async (dispatch) => {
    try {
      dispatch({
        type: CHEQUE_BY_DATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      console.log(from_date, to_date, bank_id);

      const { data } = await axios.post(
        `${API_URL}/api/cheque_entries/cheque_register`,
        { from_date, to_date, bank_id, puser_id },
        config
      );

      console.log("data", data);

      dispatch({
        type: CHEQUE_BY_DATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CHEQUE_BY_DATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };
