import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { businessUnitList } from "../../redux/actions/businessUnitActions";
import { projectList } from "../../redux/actions/projectActions";
import { supplierList } from "../../redux/actions/supplierActions";
import { chequeList } from "../../redux/actions/chequeEntryActions";
import { bankAccountList } from "../../redux/actions/bankAccountActions";

import Notification from "../../notification";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [userCreatedSuccess, setUserCreatedSuccess] = useState(false);

  const businessUnit = useSelector((state) => state.businessUnit);
  const { businessUnits } = businessUnit;

  const project = useSelector((state) => state.project);
  const { projects } = project;

  const supplier = useSelector((state) => state.supplier);
  const { suppliers } = supplier;

  const cheque = useSelector((state) => state.cheque);
  const { cheques } = cheque;

  const bankAccount = useSelector((state) => state.bankAccount);
  const { bankAccounts } = bankAccount;

  console.log("cheques", cheques);

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    dispatch(businessUnitList());
    dispatch(projectList());
    dispatch(supplierList());
    dispatch(bankAccountList());
    dispatch(chequeList());
    setUserCreatedSuccess(false);
  }, [dispatch, userData]);

  const filterdBusinessUnit =
    businessUnits.data && userData
      ? businessUnits.data
          .filter((item2) => {
            return item2.id === userData.business_unit_id;
          })
          .map((item) => item)
      : [];

  const filterdBankAcc =
    bankAccounts.data && userData
      ? bankAccounts.data
          .filter((item2) => {
            return item2.business_unit_id === userData.business_unit_id;
          })
          .map((item) => item)
      : [];

  const classes = useStyles();

  console.log("cheques", cheques);

  const filterdCheque =
    cheques.data && userData
      ? cheques.data
          .filter((item2) => {
            return item2.business_unit_id === userData.business_unit_id;
          })
          .map((item) => item)
      : null;

  return (
    <div>
      {!filterdCheque ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
            }}
          >
            <Icon style={{ fontSize: 22, marginTop: 20 }}>add_card</Icon>
            <h2 style={{ marginLeft: 5 }}>Cheque Entries</h2>
          </div>
          <Table
            cheques={filterdCheque.sort(
              (a, b) => parseFloat(b.id) - parseFloat(a.id)
            )}
            notify={notify}
            setNotify={setNotify}
            setUserCreatedSuccess={setUserCreatedSuccess}
            businessUnits={filterdBusinessUnit}
            suppliers={suppliers.data}
            projects={projects.data}
            bankAccounts={filterdBankAcc}
            userData={userData}
          />
          {userCreatedSuccess && (
            <Notification notify={notify} setNotify={setNotify} />
          )}
        </div>
      )}
    </div>
  );
};

export default Body;
