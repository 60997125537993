const modules = [
  {
    id: 1,
    app_module_id: 12,
    menu_caption: "Dashboard",
    menu_link: "/dashboard",
    menu_icon: "home",
    parent_menu: 0,
    menu_status: "A",
  },
  {
    id: 2,
    app_module_id: 12,
    menu_caption: "Companies",
    menu_link: "/companies",
    menu_icon: "domain",
    parent_menu: 0,
    menu_status: "A",
  },
  {
    id: 4,
    app_module_id: 12,
    menu_caption: "Business Units",
    menu_link: "/business_units",
    menu_icon: "domain_add",
    parent_menu: 0,
    menu_status: "A",
  },
  {
    id: 5,
    app_module_id: 12,
    menu_caption: "Projects",
    menu_link: "/projects",
    menu_icon: "format_list_bulleted",
    parent_menu: 0,
    menu_status: "A",
  },
  {
    id: 3,
    app_module_id: 12,
    menu_caption: "Suppliers",
    menu_link: "/suppliers",
    menu_icon: "local_shipping",
    parent_menu: 0,
    menu_status: "A",
  },

  {
    id: 6,
    app_module_id: 12,
    menu_caption: "Banks",
    menu_link: "/banks",
    menu_icon: "account_balance",
    parent_menu: 0,
    menu_status: "A",
  },
  {
    id: 7,
    app_module_id: 12,
    menu_caption: "Bank Accounts",
    menu_link: "/bank_accounts",
    menu_icon: "account_tree",
    parent_menu: 0,
    menu_status: "A",
  },
  {
    id: 7,
    app_module_id: 12,
    menu_caption: "Cheque Print",
    menu_link: "/cheque_entries_print",
    menu_icon: "add_card",
    parent_menu: 0,
    menu_status: "A",
  },
  {
    id: 8,
    app_module_id: 12,
    menu_caption: "Cheque Register",
    menu_link: "/cheque_register",
    menu_icon: "app_registration",
    parent_menu: 0,
    menu_status: "A",
  },
  {
    id: 8,
    app_module_id: 12,
    menu_caption: "Users",
    menu_link: "/users",
    menu_icon: "group",
    parent_menu: 0,
    menu_status: "A",
  },
];

export default modules;
