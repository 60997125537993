import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import UploadInput from "./UploadInput";
import { useDispatch, useSelector } from "react-redux";
import {
  chequeList,
  chequeUpdateAction,
} from "../../redux/actions/chequeEntryActions";
import { EditValidation } from "./FormValidation";
import Icon from "@material-ui/core/Icon";
import API_URL from "../../utils/api";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 22, marginTop: -2 }}>add_card</Icon>
      <Typography
        variant="h4"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "bold",
          flexGrow: 1,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const dispatch = useDispatch();
  const {
    openUploadPopup,
    setOpenUploadPopup,
    item,
    notify,
    setNotify,
    setUserCreatedSuccess,
    businessUnits,
    suppliers,
    projects,
    bankAccounts,
  } = props;

  const [status, setStatus] = React.useState("N");
  const [supplier_id, setSupplierId] = React.useState("");
  const [business_unit_id, setBusinessUnitId] = React.useState("");
  const [bank_account_id, setBankAccountId] = React.useState("");
  const [project_id, setProjectId] = React.useState("");
  const [cheque_img, setPhoto] = React.useState("");
  const [menu_image_preview, setMenuImagePreview] = React.useState("");
  const [filteredProject, setFilteredProject] = React.useState([]);
  const [filteredSupplier, setFilteredSupplier] = React.useState([]);
  const [payment_type, setPaymentType] = React.useState("I");
  const [print_status, setPrintStatus] = React.useState("Y");
  const [receiver_name, setReceiverName] = React.useState("");
  const [cheque_no, setChequeNo] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [issue_date, setIssueDate] = React.useState("");
  const [cheque_date, setChequeDate] = React.useState("");
  const [narration, setNarration] = React.useState("");
  const [supplier_n, setSupplier_n] = React.useState("");
  const [payment_mode, setPaymentMode] = React.useState("P");

  React.useEffect(() => {
    setStatus(item.status);
    setSupplierId(item.supplier_id);
    setBusinessUnitId(item.business_unit_id);
    setProjectId(item.project_id);
    setBankAccountId(item.bank_account_id);
    setPaymentType(item.payment_type);
    setPrintStatus(item.print_status);
    setMenuImagePreview(
      item.cheque_img !== ""
        ? `${API_URL}/api/upload-images/${item.cheque_img}`
        : ""
    );
    setPhoto(item.cheque_img);
    setReceiverName(item.receiver_name);
    setChequeNo(item.cheque_no);
    setAmount(item.amount);
    setIssueDate(item.issue_date);
    setChequeDate(item.cheque_date);
    setNarration(item.narration);
    setSupplier_n(item.supplier_n);
    setPaymentMode(item.payment_mode);
  }, [item]);

  const chequeUpdate = useSelector((state) => state.chequeUpdate);
  const { success, chequeUpdates } = chequeUpdate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("business_unit_name" in fieldValues)
      temp.business_unit_name = fieldValues.business_unit_name
        ? ""
        : "* Business Unit Name is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  const submitHandler = (e) => {
    e.preventDefault();
    if (item) {
      dispatch(
        chequeUpdateAction(
          item.id,
          payment_mode,
          business_unit_id,
          supplier_id,
          supplier_n,
          project_id,
          bank_account_id,
          receiver_name,
          cheque_no,
          amount,
          issue_date,
          cheque_date,
          narration,
          status,
          cheque_img,
          payment_type,
          print_status
        )
      );
      setOpenUploadPopup(false);
      setTimeout(() => {
        dispatch(chequeList());
      }, 1000);

      resetForm();
    }
  };

  const handleClose = () => {
    setOpenUploadPopup(false);
    resetForm();
  };

  useEffect(() => {
    if (success === true) {
      setUserCreatedSuccess(true);
      setNotify({
        isOpen: true,
        message: "Cheque Image updated successfully.",
        type: "success",
      });
    }
  }, [success]);

  const handleUploadClick = (event) => {
    console.log(event);
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    console.log("file", file);

    reader.onloadend = function (e) {
      setMenuImagePreview([reader.result]);
    };
    console.log(url); // Would see a path?

    setPhoto(file);
  };

  console.log(
    project_id,
    supplier_id,
    business_unit_id,
    receiver_name,
    cheque_no,
    amount,
    issue_date,
    cheque_date,
    narration,
    status,
    cheque_img
  );

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openUploadPopup}
      fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Upload Cheque Image
      </DialogTitle>
      <DialogContent dividers>
        <UploadInput
          setPhoto={setPhoto}
          menu_image_preview={menu_image_preview}
          setMenuImagePreview={setMenuImagePreview}
          handleUploadClick={handleUploadClick}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
