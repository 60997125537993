import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const d = new Date();
  const dv = `${d.getFullYear()}-${
    d.getMonth() > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  }-${d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`}`;

  const [values, setValues] = useState({
    receiver_name: "",
    cheque_no: "",
    amount: "",
    narration: "",
    supplier_n: "",
    issue_date: dv,
    cheque_date: dv,
    B_Unit: "",
    supplier: "",
    project: "",
    bank: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues({
      receiver_name: "",
      cheque_no: "",
      amount: "",
      issue_date: dv,
      cheque_date: dv,
      narration: "",
      supplier_n: "",
      B_Unit: "",
      supplier: "",
      project: "",
      bank: "",
    });
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    receiver_name: item ? item.receiver_name : "",
    cheque_no: item ? item.cheque_no : "",
    amount: item ? item.amount : "",
    issue_date: item ? item.issue_date : "",
    cheque_date: item ? item.cheque_date : "",
    narration: item ? item.narration : "",
    supplier_n: item ? item.supplier_n : "",
    B_Unit: "",
    supplier: "",
    project: "",
    bank: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      receiver_name: item.receiver_name,
      cheque_no: item.cheque_no,
      amount: item.amount,
      issue_date: item.issue_date,
      cheque_date: item.cheque_date,
      narration: item.narration,
      supplier_n: item.supplier_n,
    });
  }, [item]);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
