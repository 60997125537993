const modulesE = [
  {
    id: 1,
    app_module_id: 12,
    menu_caption: "Dashboard",
    menu_link: "/dashboard",
    menu_icon: "home",
    parent_menu: 0,
    menu_status: "A",
  },
  {
    id: 7,
    app_module_id: 12,
    menu_caption: "Cheque Print",
    menu_link: "/cheque_entries_print",
    menu_icon: "add_card",
    parent_menu: 0,
    menu_status: "A",
  },
  {
    id: 8,
    app_module_id: 12,
    menu_caption: "Cheque Register",
    menu_link: "/cheque_register",
    menu_icon: "app_registration",
    parent_menu: 0,
    menu_status: "A",
  },
];

export default modulesE;
