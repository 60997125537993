import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  lighten,
  makeStyles,
  withStyles,
  useTheme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import indigo from "@material-ui/core/colors/indigo";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Popup from "./Popup";
import EditPopup from "./EditPopup";
import UploadPopup from "./UploadPopup";
// import InputForm from "./InputForm";
import DeletePopup from "./DeletePopup";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";

import InfoIcon from "@material-ui/icons/Info";
import TextField from "@material-ui/core/TextField";
import Notification from "./Notification";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PrintIcon from "@material-ui/icons/Print";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

import { Link, useHistory } from "react-router-dom";
import { mdiClockDigital } from "@mdi/js";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#305089",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, searchValues) {
  const stabilizedThis = searchValues.fn(array).map((el, id) => [el, id]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "bank_account_no",
    numeric: false,
    disablePadding: false,
    label: "Account No.",
  },

  {
    id: "cheque_no",
    numeric: false,
    disablePadding: true,
    label: "Cheque No.",
  },
  {
    id: "cheque_date",
    numeric: false,
    disablePadding: false,
    label: "Cheque Date",
  },

  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Amount",
  },

  {
    id: "issue_date",
    numeric: false,
    disablePadding: false,
    label: "Issue Date",
  },

  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "",
  },

  { id: 4, numeric: false, disablePadding: false, label: "Actions" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            size="small"
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {/* {order === "desc" ? "sorted descending" : "sorted ascending"} */}
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    searchBox,
    SearchClose,
    SearchOpen,
    setOpenPopup,
    handleChange,
    searchValues,
    setSearchValues,
    handleSearch,
    printPermission,
    selected,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      style={{
        border: "1px solid #305089",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div style={{ flexGrow: 1 }}>
          <TextField
            label="Search"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </div>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <Button
            variant="outlined"
            size="medium"
            style={{ fontSize: 12 }}
            component={Link}
            aria-label="print"
            to={{
              pathname: `/app/cheque_print`,
              state: selected,
            }}
            startIcon={<PrintIcon />}
          >
            Print
          </Button>
        </Tooltip>
      ) : (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* <Tooltip title='Search'>
            <IconButton aria-label='Search' onClick={SearchOpen}>
              <SearchIcon />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title="Download">
            <IconButton aria-label="Download" disabled={!printPermission}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = (theme) => ({
  root: {
    width: "100%",
    fontSize: "0.1em",
  },
  table: {
    minWidth: 750,
  },
  tableBody: {},
  tableCell: {
    fontSize: "0.1em",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 100%",
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const EnhancedTable = (props) => {
  const {
    cheques,
    notify,
    setNotify,
    setUserCreatedSuccess,
    businessUnits,
    suppliers,
    projects,
    bankAccounts,
    userData,
  } = props;

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchBox, setSearchBox] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const [openUploadPopup, setOpenUploadPopup] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [searchValues, setSearchValues] = React.useState({
    fn: (cheques) => {
      return cheques;
    },
  });

  const [id, setId] = React.useState("");

  const SearchOpen = () => {
    setSearchBox(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    setSearchValues({
      fn: (cheques) => {
        if (target.value == "") return cheques;
        else
          return cheques.filter(
            (x) =>
              (x.bank_account_no
                ? x.bank_account_no.toLowerCase().includes(target.value)
                : null) ||
              (x.supplier_name
                ? x.supplier_name.toLowerCase().includes(target.value)
                : null) ||
              (x.bank_account_name
                ? x.bank_account_name.toLowerCase().includes(target.value)
                : null) ||
              (x.cheque_no
                ? x.cheque_no.toLowerCase().includes(target.value)
                : null) ||
              (x.amount
                ? x.amount.toString().toLowerCase().includes(target.value)
                : null) ||
              (x.receiver_name
                ? x.receiver_name.toLowerCase().includes(target.value)
                : null)
          );
      },
    });
  };

  const SearchClose = (value) => {
    setSearchBox(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    console.log("checking property value for sort", property);
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = cheques.map((n) => n.id);
      setSelected(newSelecteds);
      setSearchBox(false);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSearchBox(false);
  };

  console.log("selected", selected);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, cheques ? cheques.length : null - page * rowsPerPage);

  console.log("cheques555", cheques);

  return (
    <div className={classes.root}>
      {cheques ? (
        <div>
          <div style={{ display: "flex" }}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
              style={{ padding: 10, flexGrow: 1 }}
            >
              Cheque Entry List
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={!cheques}
              style={{ height: 34 }}
              startIcon={<AddIcon />}
              onClick={() => setOpenPopup(true)}
            >
              Add New
            </Button>
          </div>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              selected={selected}
              SearchOpen={SearchOpen}
              SearchClose={SearchClose}
              searchBox={searchBox}
              setOpenPopup={setOpenPopup}
              searchValues={searchValues}
              setSearchValues={setSearchValues}
              handleSearch={handleSearch}
            />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
                size="small"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={cheques ? cheques.length : null}
                />
                <TableBody>
                  {stableSort(
                    cheques,
                    getComparator(order, orderBy),
                    searchValues
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, id) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${id}`;

                      var today = new Date().toISOString().split("T")[0];

                      var createdAt = new Date(row.created_date)
                        .toISOString()
                        .split("T")[0];

                      console.log("today", today);
                      console.log("createdAt", createdAt);

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            onClick={(event) => handleClick(event, row.id)}
                            padding="checkbox"
                            style={{ width: "2%" }}
                          >
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              size="small"
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, width: "15%" }}
                            padding="checkbox"
                          >
                            {row.bank_account_no}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, width: "15%" }}
                            padding="checkbox"
                          >
                            {row.cheque_no}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, width: "10%" }}
                            padding="checkbox"
                          >
                            {row.cheque_date}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, width: "10%" }}
                            padding="checkbox"
                          >
                            {parseInt(row.amount)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 12, width: "10%" }}
                            padding="checkbox"
                          >
                            {row.issue_date}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              fontSize: 12,
                              width: "10%",
                            }}
                            padding="checkbox"
                          >
                            <Chip
                              size="small"
                              label={
                                row.status == "Y" ? "Cleared" : "Uncleared"
                              }
                              color="success"
                              style={{
                                backgroundColor:
                                  row.status == "Y" ? "green" : "#DC143C",
                                color: "white",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontSize: 12,
                              width: "45%",
                            }}
                            padding="checkbox"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 2,
                                marginBottom: 2,
                              }}
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                style={{
                                  fontSize: 10,
                                  marginRight: 10,
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  setOpenUploadPopup(true);
                                  setItem(row);
                                }}
                              >
                                Upload
                              </Button>

                              <Button
                                variant="outlined"
                                size="small"
                                style={{
                                  fontSize: 10,
                                  marginRight: 10,
                                  fontWeight: "bold",
                                }}
                                component={Link}
                                to={{
                                  pathname: `/app/receivable_print`,
                                  state: row,
                                }}
                              >
                                Rec. PRT
                              </Button>
                              <Button
                                variant="outlined"
                                size="small"
                                style={{
                                  fontSize: 10,
                                  marginRight: 10,
                                  fontWeight: "bold",
                                }}
                              >
                                Send SMS
                              </Button>
                            </div>
                          </TableCell>

                          <TableCell
                            align="left"
                            padding="checkbox"
                            style={{ width: "5%" }}
                          >
                            <Tooltip title="Edit">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => {
                                  setOpenEditPopup(true);
                                  setItem(row);
                                  console.log(row);
                                }}
                                style={{ marginRight: 5 }}
                                disabled={
                                  userData?.role === "A"
                                    ? false
                                    : today > createdAt
                                    ? true
                                    : false
                                }
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => {
                                  setId(row.id);
                                  setOpenDeletePopup(true);
                                  setItem(row);
                                }}
                                style={{ marginRight: 5 }}
                                disabled={today > createdAt ? true : false}
                              >
                                <DeleteIcon
                                  fontSize="small"
                                  style={{
                                    fill: today > createdAt ? "#666666" : "red",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 25, { label: "All", value: -1 }]}
              component="div"
              count={cheques.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress />
        </div>
      )}

      <Popup
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        notify={notify}
        setNotify={setNotify}
        setUserCreatedSuccess={setUserCreatedSuccess}
        businessUnits={businessUnits}
        suppliers={suppliers}
        projects={projects}
        bankAccounts={bankAccounts}
        cheques={cheques}
      ></Popup>
      <EditPopup
        setOpenEditPopup={setOpenEditPopup}
        openEditPopup={openEditPopup}
        item={item}
        notify={notify}
        setNotify={setNotify}
        setUserCreatedSuccess={setUserCreatedSuccess}
        businessUnits={businessUnits}
        suppliers={suppliers}
        projects={projects}
        bankAccounts={bankAccounts}
        cheques={cheques}
      />
      <UploadPopup
        setOpenUploadPopup={setOpenUploadPopup}
        openUploadPopup={openUploadPopup}
        item={item}
        notify={notify}
        setNotify={setNotify}
        setUserCreatedSuccess={setUserCreatedSuccess}
        businessUnits={businessUnits}
        suppliers={suppliers}
        projects={projects}
        bankAccounts={bankAccounts}
      />
      <DeletePopup
        setOpenDeletePopup={setOpenDeletePopup}
        openDeletePopup={openDeletePopup}
        id={id}
        item={item}
        notify={notify}
        setNotify={setNotify}
        setUserCreatedSuccess={setUserCreatedSuccess}
      />
    </div>
  );
};

export default EnhancedTable;
