import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box, Typography, Link } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import Icon from "@mdi/react";

import { useDispatch, useSelector } from "react-redux";
import { toggleLeftSidebar } from "../redux/actions/globalStateActions";

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";
import Fab from "@material-ui/core/Fab";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
// styles
import useStyles from "./styles";

// components
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Dashboard from "../pages/Dashboard";
import Companies from "../modules/companies";
import Suppliers from "../modules/suppliers";
import BusinessUnits from "../modules/businessUnits";
import Banks from "../modules/banks";
import BankAccounts from "../modules/bankAccounts";
import ChequeReg from "../modules/chequeReg";
import Projects from "../modules/projects";
import Users from "../modules/users";
import ChequeEntries from "../modules/chequeEntries";
import ChequePrint from "../utils/reports/chequePrint";
import ReceivablePrint from "../utils/reports/receivablePrint";

import API_URL from "../utils/api";

function Layout(props) {
  var classes = useStyles();
  var theme = useTheme();
  const [leftOpen, setLeftOpen] = useState(true);
  const [rightOpen, setRightOpen] = useState(false);

  const handleDrawerOpen = () => {
    setLeftOpen(true);
  };

  const handleDrawerClose = () => {
    setLeftOpen(false);
  };

  const dispatch = useDispatch();

  const leftSidebar = useSelector((state) => state.leftSidebar);
  const { isSidebarOpened } = leftSidebar;

  var [isPermanent, setPermanent] = useState(true);
  const [user, setUser] = useState("");

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    const getToken = JSON.parse(localStorage.getItem("userInfo"));

    const session = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : null;

    const token = "Bearer " + session?.token;

    console.log("session", session);

    fetch(`${API_URL}/api/auth/login/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    }).then((response) => {
      response.json().then((userData) => {
        console.log("Checking user Data", userData);
        setUser(userData?.data);
      });
    });
  }, []);

  const token = sessionStorage.getItem("userInfo");

  // useEffect(() => {
  //   dispatch(toggleLeftSidebar);
  // }, [dispatch]);

  if (!token) {
    <Redirect to="/login" />;
  }

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} userData={user} />
        <Sidebar userData={user} />

        <div
          className={classnames(classes.content, {
            [classes.contentShift]: isSidebarOpened,
          })}
        >
          <div
            className={classnames(classes.fab, {
              [classes.fabShift]: isSidebarOpened,
            })}
          >
            {isPermanent ? (
              <div
                className={classes.fabFixed}
                onClick={() => {
                  dispatch(toggleLeftSidebar);
                }}
              >
                {isSidebarOpened ? (
                  <NavigateBeforeIcon
                    style={{ color: "white", fontSize: 17 }}
                  />
                ) : (
                  <NavigateNextIcon style={{ color: "white", fontSize: 17 }} />
                )}
              </div>
            ) : null}
          </div>
          <div className={classes.fakeToolbar} />

          <div
            style={{
              paddingLeft: isPermanent ? 50 : 10,
              paddingRight: isPermanent ? 50 : 10,
              minHeight: "80vh",
            }}
          >
            <Switch>
              <Route path="/app/dashboard">
                <Dashboard userData={user} />
              </Route>

              <Route path="/app/companies">
                {user?.role !== "E" ? <Companies /> : null}
              </Route>

              <Route path="/app/suppliers">
                {user?.role !== "E" ? <Suppliers /> : null}
              </Route>

              <Route path="/app/business_units">
                {user?.role !== "E" ? <BusinessUnits /> : null}
              </Route>

              <Route path="/app/banks">
                {user?.role !== "E" ? <Banks /> : null}
              </Route>

              <Route path="/app/bank_accounts">
                {user?.role !== "E" ? <BankAccounts /> : null}
              </Route>

              <Route path="/app/projects">
                {user?.role !== "E" ? <Projects /> : null}
              </Route>

              <Route path="/app/cheque_entries_print">
                <ChequeEntries userData={user} />
              </Route>

              <Route path="/app/cheque_print">
                <ChequePrint userData={user} />
              </Route>

              <Route path="/app/receivable_print">
                <ReceivablePrint />
              </Route>

              <Route path="/app/cheque_register">
                <ChequeReg userData={user} />
              </Route>

              <Route path="/app/users">
                {user?.role !== "E" && user?.role !== "B" ? (
                  <Users userData={user} />
                ) : null}
              </Route>

              <Redirect exact from="/app" to="/app/dashboard" />
            </Switch>
          </div>

          <Box
            mt={5}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent="center"
          >
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="http://clonestudiobd.com/">
                Clone Studio
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </>
    </div>
  );

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Layout);
